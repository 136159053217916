@import "../../../../styles/_settings.scss", "../../../../styles/functions/_util.scss";

.zone-dialog {
    width: rem-calc(670px);
}

.zone-dialog-title {
    font-size: 24px;
    padding: 0.625rem 0.625rem 0;
}


.zone-dialog .modal-content {
    padding: 0;
    min-height: 264px;
}

.zone-dialog-items {
    display: flex;
}

.zone-dialog-item {
    flex-grow: 1;
    vertical-align: top;
    padding-right: $padding-base;
}

.zone-dialog-item-link {
    display: block;
    padding: $padding-base;
    border-radius: $border-radius-base;
    border: 1px solid transparent;

    &:hover {
        border-color: #dedddd;
    }
}

.zone-dialog-text {
    font-size: $font-size-xmedium;
    padding-right: $padding-base;
}

.zone-dialog-countries,
.zone-dialog-autocomplete-block,
.zone-dialog-cities {
    padding: $padding-base $padding-base 0;
}

.zone-dialog-cities {
    padding-bottom: $padding-base;
}

.zone-dialog-autocomplete-block {
    margin-bottom: rem-calc(15px);
}

.zone-dialog-cities {
    border-top: 1px solid #e2e3e4;
}

.zone-dialog-input-wrap {
    position: relative;
}

.zone-dialog-input-wrap > input {
    font-size: 0.8rem;
    line-height: inherit;
}

.zone-dialog-country-link {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    font-size: $font-size-medium;
    padding: $padding-base;
    border: rem-calc(1px) transparent solid;
    border-radius: $border-radius-base;
}

.zone-dialog-country-image {
    width: 30px;
    height: 18px;
    margin-right: 5px;
    display: none;
}

.zone-dialog-country-selected {
    border-color: #ccc;
}

.zone-dialog-loading {
    text-align: center;
    padding: 40px 20px;
}

.zone-dialog-country-RU {
    display: inline-block;
    background-image: url('../../../../images/zone-img/rus.svg');
}

.zone-dialog-country-UA {
    display: inline-block;
    background-image: url('../../../../images/zone-img/ua.svg')
}

.zone-dialog-country-BY {
    display: inline-block;
    background-image: url('../../../../images/zone-img/by.svg')
}

.zone-dialog-country-KZ {
    display: inline-block;
    background-image: url('../../../../images/zone-img/kz.svg')
}
